import {
  Checkbox,
  ListItem, ListItemIcon, ListItemText,
  Tooltip,
} from '@mui/material'
import terms from 'common/terms'
import RoleChip from 'components/RoleChip/RoleChip'
import { ChangeEvent, ReactElement } from 'react'
import { AccessTypes, UserGroupWithLevels } from 'services/cerbereTypes'
import { UserGroupLevels } from 'services/customTypes'

type Props = {
  groups: UserGroupWithLevels;
  index: number;
  checked: (id: string) => boolean;
  service: string;
  handleCheck: (event: ChangeEvent<HTMLInputElement>, value: string[], service: string) => void;
  showRoles: boolean;
  userPermissions: UserGroupLevels;
  permissions: UserGroupLevels;
  minCheckableLevel: string[];
}
export default function Item({
  groups, index, checked, handleCheck, service, showRoles, userPermissions, minCheckableLevel,
  permissions,
}: Props): ReactElement {
  const getItem = () => {
    if (showRoles && (Object.values(groups.hasAccessTypes).every(access => !access)
    || !userPermissions[groups.id].level1)) {
      return <></>
    }

    const isDisabled = !showRoles && permissions[groups.id]?.disabled

    const access = Object.keys(groups.hasAccessTypes)
      .filter((key => groups.hasAccessTypes[key as keyof AccessTypes]))

    const hasLevelRequirements = minCheckableLevel.some(lvl => access.includes(lvl))
    const chck = checked(groups.id)

    return (

      <ListItem className="item" sx={{ pl: index * 4 }}>
        <ListItemIcon className="checkbox">
          <Tooltip
            arrow
            placement="right"
            title={(showRoles && !hasLevelRequirements) ? terms.User.selectedLevelsNotMatching : ''}
          >
            <span>
              <Checkbox
                onChange={evt => handleCheck(evt, [groups.id], service)}
                checked={chck}
                tabIndex={-1}
                disableRipple
                disabled={isDisabled || (showRoles && !hasLevelRequirements)}
              />
            </span>
          </Tooltip>
        </ListItemIcon>
        <Tooltip
          arrow
          placement="right"
          title={(showRoles && !hasLevelRequirements) ? terms.User.selectedLevelsNotMatching : ''}
        >
          <ListItemText
            className={`list-text ${isDisabled
          || (!hasLevelRequirements && showRoles) ? 'disabled' : ''}`}
            primary={groups.displayName}
          />

        </Tooltip>
        {showRoles && <RoleChip access={groups.hasAccessTypes} />}

      </ListItem>

    )
  }

  return (
    <>
      {getItem()}

      {groups?.children?.map(child => (
        <Item
          key={child.id}
          handleCheck={handleCheck}
          service={service}
          checked={checked}
          groups={child}
          index={index + 1}
          showRoles={showRoles}
          userPermissions={userPermissions}
          minCheckableLevel={minCheckableLevel}
          permissions={permissions}
        />
      ))}
    </>
  )
}
