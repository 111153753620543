import { Button, Tooltip } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { modifyToken } from 'reducers/token'
import { Token } from 'services/cerbereTypes'

type Props= {
  token: Token;
}
// to do other errors
export default function ModifyButton({ token }: Props): ReactElement {
  const dispatch = useDispatch()
  const isDisabled = token.source !== 'CERBERE'

  const selectToken = () => {
    dispatch(modifyToken(token))
  }

  return (
    <Tooltip title={isDisabled ? terms.Token.outsideToken : ''}>
      <span>
        <Button
          className="button"
          onClick={selectToken}
          variant="outlined"
          disabled={isDisabled}
        >
          {terms.Token.modificationRequest}
        </Button>
      </span>
    </Tooltip>
  )
}
