import { TableCell, TableRow } from '@mui/material'
import { RootState } from 'Store'
import terms from 'common/terms'
import CustomTable from 'components/CustomTable/CustomTable'
import MembershipPopup from 'components/MembershipPopup/MembershipPopup'
import RequestSummary from 'components/RequestSummary/RequestSummary'
import { SUMMARY_COLUMNS } from 'components/RequestSummary/const'
import { ReactElement, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { GroupState, resetApproval, setMemberSearch } from 'reducers/group'
import GroupServices from 'services/GroupServices'
import DetailsBar from './DetailsBar'
import './GroupsDetails.scss'
import columnsHeader from './const'

export default function GroupDetails(): ReactElement {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const {
    selectedGroup, groupMembers, membersLoading, memberSearch, userRemoved, userStatusChanged, assignedUsers,
    membershipRequests, requestSummary, requestApprovals, groupSearch,
  } = useSelector((state: RootState) => state.groups as GroupState)
  const [requestPopup, setRequestPopup] = useState(false)
  const [showSummary, setSummaryPopup] = useState(false)
  const requestCount = membershipRequests.results.filter(request => request.group_id === selectedGroup.id).length

  const toggle = () => setRequestPopup(state => !state)

  const toggleSummary = () => {
    if (showSummary) {
      dispatch(resetApproval())
    }
    setSummaryPopup(state => !state)
  }

  useEffect(() => {
    if (!selectedGroup.id) return

    dispatch(GroupServices.getGroupDetails(selectedGroup.id))
  }, [selectedGroup])

  const refreshMembers = () => {
    dispatch(GroupServices.getGroupMembers({ page, groupId: selectedGroup.id, search: memberSearch }))
  }
  useEffect(() => {
    refreshMembers()
  }, [page])

  useEffect(() => {
    if (userRemoved || userStatusChanged) {
      refreshMembers()
    }
  }, [userRemoved, userStatusChanged])

  useEffect(() => {
    dispatch(setMemberSearch(''))
    if (page === 1) {
      refreshMembers()
      return
    }
    setPage(1)
  }, [assignedUsers, selectedGroup])

  useEffect(() => {
    if (page === 1) {
      refreshMembers()
      return
    }
    setPage(1)
  }, [memberSearch])

  useEffect(() => {
    if (requestSummary.length && !requestApprovals.length) {
      setRequestPopup(false)
      setSummaryPopup(true)
      refreshMembers()
      dispatch(GroupServices.getGroups(groupSearch))
    }
  }, [requestSummary])

  const membershipRow = (
    <TableRow>
      <TableCell
        className="requests"
        colSpan={columnsHeader.length}

      >
        <span>{terms.Group.membershipRequest(requestCount)}</span>
        <Button
          className="show-btn"
          variant="contained"
          onClick={() => setRequestPopup(true)}
        >
          {terms.Group.seeRequest(requestCount)}
        </Button>
      </TableCell>
    </TableRow>
  )

  return (
    <div className="details-main">
      <DetailsBar length={groupMembers.count} group={selectedGroup} />
      <CustomTable
        values={groupMembers.results}
        loader={membersLoading}
        count={groupMembers.count}
        page_size={groupMembers.page_size}
        columns={columnsHeader}
        page={page}
        changePage={val => setPage(val)}
        customRows={requestCount ? membershipRow : <></>}
      />

      <MembershipPopup
        requests={membershipRequests.results.filter(grp => grp.group_id === selectedGroup.id)}
        open={requestPopup && !requestSummary.length}
        toggle={toggle}
      />

      <RequestSummary
        requests={requestSummary}
        open={showSummary}
        toggle={toggleSummary}
        columns={SUMMARY_COLUMNS}
      />

    </div>
  )
}
